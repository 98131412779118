import $ from "jquery"
import "bootstrap"
import "./assets/styles/main.scss"

$(window).on("scroll", function() {
  if ($(window).scrollTop() > 0) {
    $(".navbar-scrolling").addClass("scroll")
  } else {
    $(".navbar-scrolling").removeClass("scroll")
  }
})


// $("#contact-us-form button").click(function(event) {
//   event.preventDefault()
//   console.log("hey")

// })




document.addEventListener("DOMContentLoaded", () => {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.getElementsByClassName("needs-validation")
  // Loop over them and prevent submission
  var validation = Array.prototype.filter.call(forms, function(form) {
    form.addEventListener(
      "submit",
      function(event) {
        if (form.checkValidity() === false) {
          event.preventDefault()
          console.log("false")
          // $("#contact-us-form button[type='submit']").prop('disabled', true);
          event.stopPropagation()
        }

        console.log(form.checkValidity())

        form.classList.add("was-validated")

        if (form.checkValidity() == true) {
          // $("#contact-us-form button[type='submit']").prop('disabled', false);
          // console.log("wow")
          event.preventDefault()

          // $("#contact-us-form").toggleClass("d-none")
          // $("#thanks").toggleClass("d-none")
          
          $.post(
            $("#contact-us-form").attr("action"),
            JSON.stringify({
              email: $("#contact-us-form input[name='email']").val(),
              message: $("#contact-us-form textarea[name='message']").val()
            })
          )
            .done(function(msg) {
              console.log("d")
              $("#contact-us-form").toggleClass("d-none")
              $("#thanks").toggleClass("d-none")
            })
            .fail(function(xhr, status, error) {
              console.log("d")
              $("#contact-us-form").toggleClass("d-none")
              $("#error").toggleClass("d-none")
            })

        }

        // form is valid
      },
      false
    )
  })
})

jQuery(document).ready(function($) {
  // $(window).scroll(function() {
  //   if ($(this).scrollTop() > 50) {
  //     $(".navbar .logo").fadeIn("slow")
  //   } else {
  //     $(".navbar .logo").fadeOut("slow")
  //   }
  // })
  $(".navbar .logo").click(function() {
    $("html, body").animate({ scrollTop: 0 }, 500)
    return false
  })
})

$("#appCarousel").on("slide.bs.carousel", function(e) {
  var $e = $(e.relatedTarget)
  var idx = $e.index()
  var itemsPerSlide = 3
  var totalItems = $(".carousel-item").length

  // if (idx >= totalItems - (itemsPerSlide - 1)) {
    // console.log("if yes")
    var it = itemsPerSlide - (totalItems - idx)
    for (var i = 0; i < it; i++) {
      // append slides to end
      if (e.direction == "left") {
        $(".carousel-item")
          .eq(i)
          .appendTo(".carousel-inner")
          // .eq(0)
          // .appendTo(".carousel-inner")
      } else {
        $(".carousel-item")
          .eq(0)
          .appendTo(".carousel-inner")
      }
    }
  // }
})